/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SQLQueryModel } from '../models/SQLQueryModel';
import type { SQLQueryResultModel } from '../models/SQLQueryResultModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SqlQueryService {

    /**
     * Get the list of queries for drop-down
     * @returns SQLQueryModel OK
     * @throws ApiError
     */
    public static getQueriesForDropDownGet(): CancelablePromise<Array<SQLQueryModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/SQLQuery/GetQueriesForDropDown',
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Save query
     * @param requestBody title and query
     * @returns SQLQueryModel OK
     * @throws ApiError
     */
    public static saveQueryPost(
requestBody?: SQLQueryModel,
): CancelablePromise<Array<SQLQueryModel>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/SQLQuery/SaveQuery',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Get results of query
     * @param requestBody 
     * @returns SQLQueryResultModel OK
     * @throws ApiError
     */
    public static executeQueryPost(
requestBody?: SQLQueryModel,
): CancelablePromise<SQLQueryResultModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/SQLQuery/ExecuteQuery',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Get results of query
     * @param programmeId 
     * @returns SQLQueryResultModel OK
     * @throws ApiError
     */
    public static getEmergencyContactsPost(
programmeId?: string,
): CancelablePromise<SQLQueryResultModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/SQLQuery/GetEmergencyContacts',
            query: {
                'programmeID': programmeId,
            },
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Get NA/Chapter Member List
     * @param nationalId 
     * @param chapterId 
     * @returns SQLQueryResultModel OK
     * @throws ApiError
     */
    public static getAreaMemberListPost(
nationalId?: string,
chapterId: string = '%',
): CancelablePromise<SQLQueryResultModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/SQLQuery/GetAreaMemberList',
            query: {
                'nationalID': nationalId,
                'chapterID': chapterId,
            },
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

}
