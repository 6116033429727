/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FormCategoryModel } from '../models/FormCategoryModel';
import type { FormDataModel } from '../models/FormDataModel';
import type { FormStructureModel } from '../models/FormStructureModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FormService {

    /**
     * Drop-down for forms manager
     * @returns FormCategoryModel OK
     * @throws ApiError
     */
    public static getFormNamesGet(): CancelablePromise<Array<FormCategoryModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Form/GetFormNames',
            errors: {
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Upload and updates Form
     * @param requestBody Form-Structure-Model
     * @returns string OK
     * @throws ApiError
     */
    public static addFormPost(
requestBody?: FormStructureModel,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Form/AddForm',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Get FormData with FormID and ParticipationID
     * @param formId Form-ID
     * @param id Programme- or National-ID
     * @param azureLogin Azure-Login
     * @param isEditMode optional: Edit the form?
     * @returns FormDataModel OK
     * @throws ApiError
     */
    public static getFormDataGet(
formId?: string,
id?: string,
azureLogin?: string,
isEditMode: boolean = false,
): CancelablePromise<FormDataModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Form/GetFormData',
            query: {
                'formID': formId,
                'id': id,
                'azureLogin': azureLogin,
                'isEditMode': isEditMode,
            },
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Upload of Form-Data
     * @param requestBody FormData-Model
     * @returns FormDataModel OK
     * @throws ApiError
     */
    public static addFormDataPost(
requestBody?: FormDataModel,
): CancelablePromise<FormDataModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Form/AddFormData',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

}
