/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EEntityType } from '../models/EEntityType';
import type { OptionModel } from '../models/OptionModel';
import type { RoleListModel } from '../models/RoleListModel';
import type { RoleModel } from '../models/RoleModel';
import type { RoleSaveModel } from '../models/RoleSaveModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RightsService {

    /**
     * Has the current user the right for this entity?
     * @param rightName Name of right
     * @param id ID of an instance
     * @returns string OK
     * @throws ApiError
     */
    public static hasRightGet(
rightName?: string,
id?: string,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Rights/HasRight',
            query: {
                'rightName': rightName,
                'id': id,
            },
        });
    }

    /**
     * Get the list of rights the current user has
     * @param entityType Entity name
 *
 * WebApp
 *
 * User
 *
 * Resource
 *
 * Form
 *
 * Directory
 *
 * Region
 *
 * National
 *
 * Chapter
 *
 * Programme
 *
 * Invitation
     * @param id ID of an instance
     * @returns string OK
     * @throws ApiError
     */
    public static getRightsGet(
entityType?: EEntityType,
id?: string,
): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Rights/GetRights',
            query: {
                'entityType': entityType,
                'id': id,
            },
        });
    }

    /**
     * Get the list all rights
     * @returns OptionModel OK
     * @throws ApiError
     */
    public static getAllRightsGet(): CancelablePromise<Array<OptionModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Rights/GetAllRights',
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Get the list of roles
     * @returns RoleListModel OK
     * @throws ApiError
     */
    public static getRolesGet(): CancelablePromise<Array<RoleListModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Rights/GetRoles',
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Get the role details
     * @param id ID of role
     * @returns RoleModel OK
     * @throws ApiError
     */
    public static getRoleGet(
id?: string,
): CancelablePromise<RoleModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Rights/GetRole',
            query: {
                'id': id,
            },
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Set the role details
     * @param add optional: Add a new role?
     * @param requestBody Role data
     * @returns RoleModel OK
     * @throws ApiError
     */
    public static setRolePost(
add: boolean = false,
requestBody?: RoleSaveModel,
): CancelablePromise<RoleModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Rights/SetRole',
            query: {
                'add': add,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
                422: `Unprocessable Content`,
            },
        });
    }

}
