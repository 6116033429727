/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddressListModel } from '../models/AddressListModel';
import type { ContactModel } from '../models/ContactModel';
import type { DIFModel } from '../models/DIFModel';
import type { EditParticipantsModel } from '../models/EditParticipantsModel';
import type { EFormListModel } from '../models/EFormListModel';
import type { FormCategoryModel } from '../models/FormCategoryModel';
import type { HostList } from '../models/HostList';
import type { InvitationList } from '../models/InvitationList';
import type { InvitationModel } from '../models/InvitationModel';
import type { ParticipationCertificateModel } from '../models/ParticipationCertificateModel';
import type { ParticipationRoleModel } from '../models/ParticipationRoleModel';
import type { ParticipationsModel } from '../models/ParticipationsModel';
import type { ProgrammeDataModel } from '../models/ProgrammeDataModel';
import type { ProgrammeDetailModel } from '../models/ProgrammeDetailModel';
import type { ProgrammeViewModel } from '../models/ProgrammeViewModel';
import type { TIFListModel } from '../models/TIFListModel';
import type { TIFModel } from '../models/TIFModel';
import type { UserViewModel } from '../models/UserViewModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProgrammeService {

    /**
     * Get detail-data of a programme
     * @param id Reference-number
     * @returns ProgrammeViewModel OK
     * @throws ApiError
     */
    public static getProgrammeGet(
id?: string,
): CancelablePromise<ProgrammeViewModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Programme/GetProgramme',
            query: {
                'id': id,
            },
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Set Chapter for a programme
     * @param requestBody Programme-Model for Edit
     * @returns ProgrammeViewModel OK
     * @throws ApiError
     */
    public static setProgrammeDetailPost(
requestBody?: ProgrammeDetailModel,
): CancelablePromise<ProgrammeViewModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Programme/SetProgrammeDetail',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Set Chapter for a programme
     * @param requestBody Programme-Model for Edit
     * @returns string OK
     * @throws ApiError
     */
    public static setTrackCertificatesPost(
requestBody?: ParticipationCertificateModel,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Programme/SetTrackCertificates',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Set Chapter for a invitation
     * @param requestBody Programme-Model for Edit
     * @returns ProgrammeViewModel OK
     * @throws ApiError
     */
    public static setInvitationChapterPost(
requestBody?: InvitationModel,
): CancelablePromise<ProgrammeViewModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Programme/SetInvitationChapter',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Get participants of a programme
     * @param id Reference-number
     * @returns ParticipationsModel OK
     * @throws ApiError
     */
    public static getParticipantsGet(
id?: string,
): CancelablePromise<Array<ParticipationsModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Programme/GetParticipants',
            query: {
                'id': id,
            },
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Set participants of a programme
     * @param requestBody Model
     * @returns ParticipationsModel OK
     * @throws ApiError
     */
    public static setParticipantListPost(
requestBody?: EditParticipantsModel,
): CancelablePromise<Array<ParticipationsModel>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Programme/SetParticipantList',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Get Participation-Roles for DropwDown
     * @param programmeType Type of Programme
     * @param host Get roles for host?
     * @returns ParticipationRoleModel OK
     * @throws ApiError
     */
    public static getParticipationRolesGet(
programmeType?: string,
host?: boolean,
): CancelablePromise<Array<ParticipationRoleModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Programme/GetParticipationRoles',
            query: {
                'programmeType': programmeType,
                'host': host,
            },
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Get address list of a programme
     * @param id Reference-number
     * @returns AddressListModel OK
     * @throws ApiError
     */
    public static getAddressListGet(
id?: string,
): CancelablePromise<Array<AddressListModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Programme/GetAddressList',
            query: {
                'id': id,
            },
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Get DIF list of a programme
     * @param programmeId Reference-number
     * @returns DIFModel OK
     * @throws ApiError
     */
    public static getDifget(
programmeId?: string,
): CancelablePromise<Array<DIFModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Programme/GetDIF',
            query: {
                'programmeID': programmeId,
            },
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Set programme contact data
     * @param referenceNumber Reference number
     * @param requestBody Model of Contact Entity to set
     * @returns ContactModel OK
     * @throws ApiError
     */
    public static setProgrammeContactPost(
referenceNumber?: string,
requestBody?: ContactModel,
): CancelablePromise<ContactModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Programme/SetProgrammeContact',
            query: {
                'referenceNumber': referenceNumber,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Set programme data
     * @param referenceNumber Reference number
     * @param requestBody Model of programme data to set
     * @returns ProgrammeDataModel OK
     * @throws ApiError
     */
    public static setProgrammeDataPost(
referenceNumber?: string,
requestBody?: ProgrammeDataModel,
): CancelablePromise<ProgrammeDataModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Programme/SetProgrammeData',
            query: {
                'referenceNumber': referenceNumber,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Get list of host-programmes
     * @param year Filter
     * @param showPast Show all hosts (include past)
     * @returns HostList OK
     * @throws ApiError
     */
    public static getHostlistGet(
year?: number,
showPast: boolean = false,
): CancelablePromise<HostList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Programme/GetHostlist',
            query: {
                'year': year,
                'showPast': showPast,
            },
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Set Status and/or chapter for programmes in hostlist
     * @param requestBody Programme-Model for Edit
     * @returns HostList OK
     * @throws ApiError
     */
    public static setHostlistPost(
requestBody?: HostList,
): CancelablePromise<HostList> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Programme/SetHostlist',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Get list of invitations of programmes
     * @param year Filter
     * @param showPast Show all invitations (include past)
     * @param participationsBulkTemplate include all invitations and data for use as participation uplift template
     * @returns InvitationList OK
     * @throws ApiError
     */
    public static getInvitationListGet(
year?: number,
showPast: boolean = false,
participationsBulkTemplate: boolean = false,
): CancelablePromise<InvitationList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Programme/GetInvitationList',
            query: {
                'year': year,
                'showPast': showPast,
                'participationsBulkTemplate': participationsBulkTemplate,
            },
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Set Status and/or notes for invitations
     * @param requestBody Programme-Model for Edit
     * @returns InvitationList OK
     * @throws ApiError
     */
    public static setInvitationlistPost(
requestBody?: InvitationList,
): CancelablePromise<InvitationList> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Programme/SetInvitationlist',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Get TIFs of an invitation
     * @param invitationId Invitation-ID
     * @param programmeId Reference-Number
     * @returns TIFModel OK
     * @throws ApiError
     */
    public static getTiFsGet(
invitationId?: string,
programmeId?: string,
): CancelablePromise<Array<TIFModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Programme/GetTIFs',
            query: {
                'invitationID': invitationId,
                'programmeID': programmeId,
            },
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Set TIF of an invitation
     * @param requestBody model
     * @returns TIFModel OK
     * @throws ApiError
     */
    public static setTifpost(
requestBody?: TIFModel,
): CancelablePromise<Array<TIFModel>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Programme/SetTIF',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Get participants of an invitation
     * @param invitationId Invitation-ID
     * @param programmeId Reference-Number
     * @param tifName TIF-Name
     * @returns UserViewModel OK
     * @throws ApiError
     */
    public static getParticipantsByInvitationGet(
invitationId?: string,
programmeId?: string,
tifName?: string,
): CancelablePromise<Array<UserViewModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Programme/GetParticipantsByInvitation',
            query: {
                'invitationID': invitationId,
                'programmeID': programmeId,
                'tifName': tifName,
            },
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Set TIF for participant
     * @param tifId TIF-ID
     * @param invitationId Invitation-ID
     * @param programmeId Programme-ID
     * @param upn Azure-Login
     * @param add Add TIF to participation
     * @param del Remove TIF from participation
     * @returns UserViewModel OK
     * @throws ApiError
     */
    public static setTifByParticipantPost(
tifId?: string,
invitationId?: string,
programmeId?: string,
upn?: string,
add?: boolean,
del?: boolean,
): CancelablePromise<Array<UserViewModel>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Programme/SetTIFByParticipant',
            query: {
                'tifID': tifId,
                'invitationID': invitationId,
                'programmeID': programmeId,
                'upn': upn,
                'add': add,
                'del': del,
            },
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Get TIF list of a programme
     * @param programmeId Reference-number
     * @returns TIFListModel OK
     * @throws ApiError
     */
    public static getTifListGet(
programmeId?: string,
): CancelablePromise<Array<TIFListModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Programme/GetTIFList',
            query: {
                'programmeID': programmeId,
            },
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Get EForms list of particpants of a programme
     * @param programmeId Reference-number
     * @returns EFormListModel OK
     * @throws ApiError
     */
    public static getEFormListGet(
programmeId?: string,
): CancelablePromise<Array<EFormListModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Programme/GetEFormList',
            query: {
                'programmeID': programmeId,
            },
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Get list of available forms of a programme
     * @param programmeId Reference-number
     * @returns FormCategoryModel OK
     * @throws ApiError
     */
    public static getAvailableFormsGet(
programmeId?: string,
): CancelablePromise<Array<FormCategoryModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Programme/GetAvailableForms',
            query: {
                'programmeID': programmeId,
            },
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Forms of a programme
     * @param programmeId Reference nr.
     * @param azureLogin Azure login
     * @returns FormCategoryModel OK
     * @throws ApiError
     */
    public static getFormsGet(
programmeId?: string,
azureLogin?: string,
): CancelablePromise<Array<FormCategoryModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Programme/GetForms',
            query: {
                'programmeID': programmeId,
                'azureLogin': azureLogin,
            },
            errors: {
                422: `Unprocessable Content`,
            },
        });
    }

}
