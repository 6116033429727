/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FinanceModel } from '../models/FinanceModel';
import type { FinanceSageModel } from '../models/FinanceSageModel';
import type { VoucherModel } from '../models/VoucherModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FinanceService {

    /**
     * Get statements from all programmes
     * Results only get fetched with a filter set
     * @param year optional: filter by start-date in year
     * @param month optional: filter by month/quarter in year
     * @param nationalId optional: from national details view
     * @param chapterId optional: from chapter details view
     * @returns FinanceModel OK
     * @throws ApiError
     */
    public static getAllStatementsGet(
year?: number,
month?: number,
nationalId?: string,
chapterId?: string,
): CancelablePromise<FinanceModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Finance/GetAllStatements',
            query: {
                'year': year,
                'month': month,
                'nationalID': nationalId,
                'chapterID': chapterId,
            },
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Set statements from calculation
     * Only statements which aren't imported to sage
     * @param year Only calculate programmes which have started in the chosen year
     * @returns FinanceModel OK
     * @throws ApiError
     */
    public static setStatementsPost(
year?: number,
): CancelablePromise<FinanceModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Finance/SetStatements',
            query: {
                'year': year,
            },
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Add statement for correction
     * @param requestBody finance model
     * @returns FinanceModel OK
     * @throws ApiError
     */
    public static addStatementPost(
requestBody?: FinanceSageModel,
): CancelablePromise<FinanceModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Finance/AddStatement',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Set statements ready for sage
     * @param year Filter programmes by start-date in year
     * @returns FinanceModel OK
     * @throws ApiError
     */
    public static statementsToSagePost(
year?: number,
): CancelablePromise<FinanceModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Finance/StatementsToSage',
            query: {
                'year': year,
            },
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Get Voucher data
     * @param year filter statements by year of start-date
     * @param month optional: 0...given year, 1..12...month, -1..-4...quarter
     * @param areaId optional: filter by area
     * @returns VoucherModel OK
     * @throws ApiError
     */
    public static getVoucherGet(
year?: number,
month?: number,
areaId?: string,
): CancelablePromise<Array<VoucherModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Finance/GetVoucher',
            query: {
                'year': year,
                'month': month,
                'areaID': areaId,
            },
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

}
