/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChapterViewModel } from '../models/ChapterViewModel';
import type { CountryModel } from '../models/CountryModel';
import type { NationalViewModel } from '../models/NationalViewModel';
import type { VersionViewModel } from '../models/VersionViewModel';
import type { WPPageResult } from '../models/WPPageResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CommonService {

    /**
     * Get Version-Information of application
     * @returns VersionViewModel OK
     * @throws ApiError
     */
    public static getVersionGet(): CancelablePromise<VersionViewModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Common/GetVersion',
        });
    }

    /**
     * Get List of Nationals and Chapters
     * @param nationalId Filter chapter per national
     * @returns ChapterViewModel OK
     * @throws ApiError
     */
    public static getNationalsAndChaptersGet(
nationalId?: string,
): CancelablePromise<Array<ChapterViewModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Common/GetNationalsAndChapters',
            query: {
                'nationalID': nationalId,
            },
            errors: {
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Get detail-data of a chapter
     * @param id Chapter-ID
     * @returns ChapterViewModel OK
     * @throws ApiError
     */
    public static getChapterGet(
id?: string,
): CancelablePromise<ChapterViewModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Common/GetChapter',
            query: {
                'id': id,
            },
            errors: {
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Get detail-data of a national
     * @param id Country-ID
     * @returns NationalViewModel OK
     * @throws ApiError
     */
    public static getNationalGet(
id?: string,
): CancelablePromise<NationalViewModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Common/GetNational',
            query: {
                'id': id,
            },
            errors: {
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Get countries
     * @returns CountryModel OK
     * @throws ApiError
     */
    public static getCountriesGet(): CancelablePromise<Array<CountryModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Common/GetCountries',
            errors: {
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Get WordPress data
     * @param slug slug of WorpPress page
     * @returns WPPageResult OK
     * @throws ApiError
     */
    public static getWordPressBySlugGet(
slug?: string,
): CancelablePromise<WPPageResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Common/GetWordPressBySlug',
            query: {
                'slug': slug,
            },
            errors: {
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Get WordPress data
     * @param id id of WorpPress page
     * @returns WPPageResult OK
     * @throws ApiError
     */
    public static getWordPressByIdget(
id?: number,
): CancelablePromise<WPPageResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Common/GetWordPressByID',
            query: {
                'id': id,
            },
            errors: {
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Expire all cached WordPress data
     * @param apiKey Security-Key
     * @returns void 
     * @throws ApiError
     */
    public static expireWordPressCachePost(
apiKey?: string,
): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Common/ExpireWordPressCache',
            query: {
                'apiKey': apiKey,
            },
            errors: {
                403: `Forbidden`,
            },
        });
    }

    /**
     * Expire all cached object by type data
     * e.g. Filter-Criteria for Resource
     * @param apiKey Security-Key
     * @returns void 
     * @throws ApiError
     */
    public static expireObjectByTypeCachePost(
apiKey?: string,
): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Common/ExpireObjectByTypeCache',
            query: {
                'apiKey': apiKey,
            },
            errors: {
                403: `Forbidden`,
            },
        });
    }

    /**
     * Expire all cached JSON data
     * e.g. Filter-Criteria for Resource
     * @param apiKey Security-Key
     * @returns void 
     * @throws ApiError
     */
    public static expireJsonCachePost(
apiKey?: string,
): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Common/ExpireJSONCache',
            query: {
                'apiKey': apiKey,
            },
            errors: {
                403: `Forbidden`,
            },
        });
    }

    /**
     * Send Notifications
     * @param refNr Programme-ID
     * @param body Mail content
     * @returns string OK
     * @throws ApiError
     */
    public static sendMailPost(
refNr?: string,
body?: string,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Common/SendMail',
            query: {
                'refNr': refNr,
                'body': body,
            },
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

}
