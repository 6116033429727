/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AchievementBaseModel } from '../models/AchievementBaseModel';
import type { AchievementModel } from '../models/AchievementModel';
import type { AddressModel } from '../models/AddressModel';
import type { Certifications } from '../models/Certifications';
import type { ChangePasswordModel } from '../models/ChangePasswordModel';
import type { CommunicationOptionsModel } from '../models/CommunicationOptionsModel';
import type { EmailModel } from '../models/EmailModel';
import type { GroupedCV } from '../models/GroupedCV';
import type { GuardianLinkModel } from '../models/GuardianLinkModel';
import type { LoginModel } from '../models/LoginModel';
import type { NationalAndChapter } from '../models/NationalAndChapter';
import type { ParticipationModel } from '../models/ParticipationModel';
import type { PersonalModel } from '../models/PersonalModel';
import type { PhoneNumbersModel } from '../models/PhoneNumbersModel';
import type { ProfileProgressModel } from '../models/ProfileProgressModel';
import type { RegisterUserCheckResult } from '../models/RegisterUserCheckResult';
import type { RegisterUserModel } from '../models/RegisterUserModel';
import type { RelatedUserModel } from '../models/RelatedUserModel';
import type { UserSkillsModel } from '../models/UserSkillsModel';
import type { UserViewModel } from '../models/UserViewModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * Check, if user is possible a duplicate
     * @param reChaptcha Token from browser
     * @param requestBody User data for Registration
     * @returns RegisterUserCheckResult OK
     * @throws ApiError
     */
    public static checkRegisterPost(
reChaptcha?: string,
requestBody?: RegisterUserModel,
): CancelablePromise<Array<RegisterUserCheckResult>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/User/CheckRegister',
            query: {
                'reCHAPTCHA': reChaptcha,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Register a new user
     * @param requestBody User data for Registration
     * @returns RegisterUserModel OK
     * @throws ApiError
     */
    public static registerPost(
requestBody?: RegisterUserModel,
): CancelablePromise<RegisterUserModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/User/Register',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Get Login-Model for current user
     * @returns LoginModel OK
     * @throws ApiError
     */
    public static getCurrentUserGet(): CancelablePromise<LoginModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/User/GetCurrentUser',
            errors: {
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Get UPN/Login-Name for user
     * @param givenName Given-Name
     * @param surName Surname
     * @param chapterId Chapter-ID
     * @param middleName optional: Middle-Name
     * @returns string OK
     * @throws ApiError
     */
    public static createUpnget(
givenName?: string,
surName?: string,
chapterId?: string,
middleName?: string,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/User/CreateUPN',
            query: {
                'givenName': givenName,
                'surName': surName,
                'chapterID': chapterId,
                'middleName': middleName,
            },
        });
    }

    /**
     * Get detail-data from user
     * @param upn Azure-Login
     * @returns UserViewModel OK
     * @throws ApiError
     */
    public static getUserGet(
upn?: string,
): CancelablePromise<UserViewModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/User/GetUser',
            query: {
                'upn': upn,
            },
            errors: {
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Change password of current user
     * @param requestBody ChangePassword-Model
     * @returns string OK
     * @throws ApiError
     */
    public static changePasswordPost(
requestBody?: ChangePasswordModel,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/User/ChangePassword',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Get personal information from user
     * @param upn Azure-Login
     * @returns PersonalModel OK
     * @throws ApiError
     */
    public static getPersonalInformationGet(
upn?: string,
): CancelablePromise<PersonalModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/User/GetPersonalInformation',
            query: {
                'upn': upn,
            },
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Save personal information from user
     * @param requestBody Personal-Data-Model
     * @returns PersonalModel OK
     * @throws ApiError
     */
    public static setPersonalInformationPost(
requestBody?: PersonalModel,
): CancelablePromise<PersonalModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/User/SetPersonalInformation',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Get address from user
     * @param upn Azure-Login
     * @returns AddressModel OK
     * @throws ApiError
     */
    public static getAddressGet(
upn?: string,
): CancelablePromise<AddressModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/User/GetAddress',
            query: {
                'upn': upn,
            },
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Set address from user
     * @param replace replace address?
     * @param requestBody Address-Model
     * @returns AddressModel OK
     * @throws ApiError
     */
    public static setAddressPost(
replace: boolean = false,
requestBody?: AddressModel,
): CancelablePromise<AddressModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/User/SetAddress',
            query: {
                'replace': replace,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Get emails from user
     * @param upn Azure-Login
     * @returns EmailModel OK
     * @throws ApiError
     */
    public static getEmailsGet(
upn?: string,
): CancelablePromise<Array<EmailModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/User/GetEmails',
            query: {
                'upn': upn,
            },
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Set email from user
     * @param add Add email
     * @param del Delete email
     * @param setPrime change prime email
     * @param requestBody Email
     * @returns EmailModel OK
     * @throws ApiError
     */
    public static setEmailPost(
add: boolean = false,
del: boolean = false,
setPrime: boolean = false,
requestBody?: EmailModel,
): CancelablePromise<Array<EmailModel>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/User/SetEmail',
            query: {
                'add': add,
                'del': del,
                'setPrime': setPrime,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Get Phones(phone, mobile, fax, ...) from user
     * @param upn Azure-Login
     * @returns PhoneNumbersModel OK
     * @throws ApiError
     */
    public static getPhoneNumbersGet(
upn?: string,
): CancelablePromise<Array<PhoneNumbersModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/User/GetPhoneNumbers',
            query: {
                'upn': upn,
            },
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Set phone from user
     * @param add Add email
     * @param del Delete email
     * @param setPrime change prime email
     * @param requestBody Phone
     * @returns PhoneNumbersModel OK
     * @throws ApiError
     */
    public static setPhoneNumbersPost(
add: boolean = false,
del: boolean = false,
setPrime: boolean = false,
requestBody?: PhoneNumbersModel,
): CancelablePromise<Array<PhoneNumbersModel>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/User/SetPhoneNumbers',
            query: {
                'add': add,
                'del': del,
                'setPrime': setPrime,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Get Participations
     * @param upn Azure Login (me or children or user)
     * @returns ParticipationModel OK
     * @throws ApiError
     */
    public static getParticipationsGet(
upn?: string,
): CancelablePromise<Array<ParticipationModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/User/GetParticipations',
            query: {
                'upn': upn,
            },
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Get primary National and Chapter
     * @param upn Azure-Login
     * @returns NationalAndChapter OK
     * @throws ApiError
     */
    public static getPrimaryNationalAndChapterGet(
upn?: string,
): CancelablePromise<NationalAndChapter> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/User/GetPrimaryNationalAndChapter',
            query: {
                'upn': upn,
            },
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Get Chapters
     * @param upn Azure-Login
     * @returns NationalAndChapter OK
     * @throws ApiError
     */
    public static getChaptersGet(
upn?: string,
): CancelablePromise<Array<NationalAndChapter>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/User/GetChapters',
            query: {
                'upn': upn,
            },
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Set Chapter
     * @param add Add Chapter
     * @param del Remove Chapter
     * @param setPrimary new primary Chapter
     * @param requestBody Chapter
     * @returns NationalAndChapter OK
     * @throws ApiError
     */
    public static setChapterPost(
add?: boolean,
del?: boolean,
setPrimary?: boolean,
requestBody?: NationalAndChapter,
): CancelablePromise<Array<NationalAndChapter>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/User/SetChapter',
            query: {
                'add': add,
                'del': del,
                'setPrimary': setPrimary,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Get profile progress
     * @param upn Azure-Login
     * @returns ProfileProgressModel OK
     * @throws ApiError
     */
    public static getProfileProgressGet(
upn?: string,
): CancelablePromise<ProfileProgressModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/User/GetProfileProgress',
            query: {
                'upn': upn,
            },
            errors: {
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Get skills from user
     * @param upn Azure-Login
     * @returns UserSkillsModel OK
     * @throws ApiError
     */
    public static getSkillsGet(
upn?: string,
): CancelablePromise<UserSkillsModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/User/GetSkills',
            query: {
                'upn': upn,
            },
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Set skills from user
     * @param requestBody Skills-Model
     * @returns UserSkillsModel OK
     * @throws ApiError
     */
    public static setSkillsPost(
requestBody?: UserSkillsModel,
): CancelablePromise<UserSkillsModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/User/SetSkills',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Get achievements from user
     * @param upn Azure-Login
     * @returns AchievementBaseModel OK
     * @throws ApiError
     */
    public static getAchievementsGet(
upn?: string,
): CancelablePromise<Array<AchievementBaseModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/User/GetAchievements',
            query: {
                'upn': upn,
            },
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Get achievements from all user
     * Results only get fetched with a filter set
     * @param areaId area filter
     * @param roleId role filter
     * @param certificationId certification filter
     * @param expirationYear expiration year filter
     * @returns AchievementModel OK
     * @throws ApiError
     */
    public static getAllAchievementsGet(
areaId?: string,
roleId?: string,
certificationId?: number,
expirationYear?: number,
): CancelablePromise<Array<AchievementModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/User/GetAllAchievements',
            query: {
                'areaID': areaId,
                'roleID': roleId,
                'certificationID': certificationId,
                'expirationYear': expirationYear,
            },
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Get Curriculum Vitae from user
     * @param upn Azure-Login
     * @returns GroupedCV OK
     * @throws ApiError
     */
    public static getCvget(
upn?: string,
): CancelablePromise<Array<GroupedCV>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/User/GetCV',
            query: {
                'upn': upn,
            },
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Get children
     * @returns RelatedUserModel OK
     * @throws ApiError
     */
    public static getChildrenGet(): CancelablePromise<Array<RelatedUserModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/User/GetChildren',
            errors: {
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Set child
     * @param requestBody Model
     * @returns RelatedUserModel OK
     * @throws ApiError
     */
    public static registerChildPost(
requestBody?: RegisterUserModel,
): CancelablePromise<Array<RelatedUserModel>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/User/RegisterChild',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Get guardians
     * @returns RelatedUserModel OK
     * @throws ApiError
     */
    public static getGuardiansGet(): CancelablePromise<Array<RelatedUserModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/User/GetGuardians',
            errors: {
                401: `Unauthorized`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Set guardian relation to disableRole
     * @param guardianId guardianID
     * @returns RelatedUserModel OK
     * @throws ApiError
     */
    public static childSelfResponsibilityPost(
guardianId?: string,
): CancelablePromise<Array<RelatedUserModel>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/User/ChildSelfResponsibility',
            query: {
                'guardianID': guardianId,
            },
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Get relatives
     * @param upn Azure-Login
     * @returns RelatedUserModel OK
     * @throws ApiError
     */
    public static getRelativesGet(
upn?: string,
): CancelablePromise<Array<RelatedUserModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/User/GetRelatives',
            query: {
                'upn': upn,
            },
            errors: {
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Get communication options
     * @param upn UPN of user to get data for
     * @returns CommunicationOptionsModel OK
     * @throws ApiError
     */
    public static getCommunicationOptionsGet(
upn?: string,
): CancelablePromise<CommunicationOptionsModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/User/GetCommunicationOptions',
            query: {
                'upn': upn,
            },
            errors: {
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Set Guardian-link
     * @param upnGuardian UPN of guardian to link with
     * @param upnChild UPN of child to link with
     * @returns GuardianLinkModel OK
     * @throws ApiError
     */
    public static setGuardianLinkPost(
upnGuardian?: string,
upnChild?: string,
): CancelablePromise<GuardianLinkModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/User/SetGuardianLink',
            query: {
                'upnGuardian': upnGuardian,
                'upnChild': upnChild,
            },
            errors: {
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Set communication options
     * @param upn UPN of user to set data for
     * @param requestBody Options to set
     * @returns CommunicationOptionsModel OK
     * @throws ApiError
     */
    public static setCommunicationOptionsPost(
upn?: string,
requestBody?: CommunicationOptionsModel,
): CancelablePromise<CommunicationOptionsModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/User/SetCommunicationOptions',
            query: {
                'upn': upn,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Get User from Adminregistration
     * Bypass duplication check in behalve of user
     * @param upn Model
     * @returns RegisterUserModel OK
     * @throws ApiError
     */
    public static userbyAdminregistrationGet(
upn?: string,
): CancelablePromise<RegisterUserModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/User/UserbyAdminregistration',
            query: {
                'upn': upn,
            },
            errors: {
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Set User on behalve
     * Bypass duplication check in behalve of user
     * @param requestBody Model
     * @returns RegisterUserModel OK
     * @throws ApiError
     */
    public static registerbyAdminPost(
requestBody?: RegisterUserModel,
): CancelablePromise<RegisterUserModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/User/RegisterbyAdmin',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Unprocessable Content`,
            },
        });
    }

    /**
     * Get certifications for drop-down
     * @returns Certifications OK
     * @throws ApiError
     */
    public static getCertificationsGet(): CancelablePromise<Array<Certifications>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/User/GetCertifications',
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Content`,
            },
        });
    }

}
